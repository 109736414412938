import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../components/seo'

const IndexPage = () => {
  return (
    <Layout pageTitle="Home Page">
      <h1 className="text-3xl font-bold underline">
        Hello world!
      </h1>
      <div class="grid grid-cols-10 gap-2">
        <div class="bg-indigo-50 aspect-square"></div>
        <div class="bg-indigo-100 aspect-square"></div>
        <div class="bg-indigo-200 aspect-square"></div>
        <div class="bg-indigo-300 aspect-square"></div>
        <div class="bg-indigo-400 aspect-square"></div>
        <div class="bg-indigo-500 aspect-square"></div>
        <div class="bg-indigo-600 aspect-square"></div>
        <div class="bg-indigo-700 aspect-square"></div>
        <div class="bg-indigo-800 aspect-square"></div>
        <div class="bg-indigo-900 aspect-square"></div>
      </div>
      <p>I'm making this by following the Gatsby Tutorial.</p>
      <StaticImage
        alt="Clifford, a reddish-brown pitbull, dozing in a bean bag chair"
        src="../images/clifford.jpeg"
      />
    </Layout>
  )
}

export const Head = () => <Seo title="Home Page" />

export default IndexPage
